//
// custom-forms.scss
// Extended from Bootstrap
//

//
// Bootstrap Overrides ===================================
//

// Custom radio button boxed
$custom-control-padding-left: $custom-control-gutter + $custom-control-indicator-size;
.custom-radio-boxed {
  padding: 1rem $custom-control-padding-left 1rem $custom-control-padding-left + $custom-control-gutter;
  border-radius: 0.5rem;
  border: 1px solid $input-border-color;
  margin: 0.5rem auto;
  width: 100%;
  cursor: pointer;

  .custom-control-label {
    display: flex;
    align-items: center;
    width: 100%;
  }
}
