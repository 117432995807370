//
// jumbotron.scss
// Extended from Bootstrap
//
@use 'sass:math';

//
// Bootstrap Overrides =====================================
//

.jumbotron {
  padding: (math.div($jumbotron-padding, 2));
  @include media-breakpoint-up(sm) {
    padding: $jumbotron-padding;
  }
}
