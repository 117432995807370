// _sbam.scss

@use "sass:map";

// For more information: https://material.angular.io/guide/theming
@use "@angular/material" as mat;
@use "@angular/material-experimental" as matx;

// Themes definitions.
@use "themes/light";
@use "themes/dark";

// Theme variables
@use "variables";

@use "mixins";
@use "components";
@use "themes/config/typography";
@use "themes/config/icons";
@use "themes/config/palette";
@use "themes/config/m3-palette" as m3palette;

// Fix mat text field label for density < -1
@use "@material/textfield";
textfield.$minimum-height-for-filled-label: 10px;

//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
@include mat.all-component-typographies(typography.$sbam-typography);

// Be sure that you only ever include this mixin once!
@include mat.core();

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
html {
  @include mat.core-theme(light.$sbam-light-theme-legacy);
  @include mat.card-theme(light.$sbam-light-theme-legacy);
  @include mat.progress-bar-theme(light.$sbam-light-theme-legacy);
  @include mat.tooltip-theme(light.$sbam-light-theme-legacy);
  @include mat.form-field-theme(light.$sbam-light-theme-legacy);
  @include mat.input-theme(light.$sbam-light-theme-legacy);
  @include mat.select-theme(light.$sbam-light-theme-legacy);
  @include mat.autocomplete-theme(light.$sbam-light-theme-legacy);
  @include mat.dialog-theme(light.$sbam-light-theme-legacy);
  @include mat.chips-theme(m3palette.$light-theme);
  @include mat.slide-toggle-theme(light.$sbam-light-theme-legacy);
  @include mat.radio-theme(light.$sbam-light-theme-legacy);
  @include mat.slider-theme(light.$sbam-light-theme-legacy);
  @include mat.menu-theme(light.$sbam-light-theme-legacy);
  @include mat.list-theme(light.$sbam-light-theme-legacy);
  @include mat.paginator-theme(light.$sbam-light-theme-legacy);
  @include mat.tabs-theme(light.$sbam-light-theme-legacy);
  @include mat.checkbox-theme(light.$sbam-light-theme-legacy);
  @include mat.button-theme(light.$sbam-light-theme-legacy);
  @include mat.fab-theme(m3palette.$light-theme);
  @include mat.icon-button-theme(light.$sbam-light-theme-legacy);
  @include mat.snack-bar-theme(light.$sbam-light-theme-legacy);
  @include mat.table-theme(light.$sbam-light-theme-legacy);
  @include mat.progress-spinner-theme(light.$sbam-light-theme-legacy);
  @include mat.bottom-sheet-theme(light.$sbam-light-theme-legacy);
  @include mat.button-toggle-theme(light.$sbam-light-theme-legacy);
  @include mat.datepicker-theme(light.$sbam-light-theme-legacy);
  @include mat.divider-theme(light.$sbam-light-theme-legacy);
  @include mat.expansion-theme(light.$sbam-light-theme-legacy);
  @include mat.grid-list-theme(light.$sbam-light-theme-legacy);
  @include mat.icon-theme(light.$sbam-light-theme-legacy);
  @include mat.sidenav-theme(light.$sbam-light-theme-legacy);
  @include mat.stepper-theme(light.$sbam-light-theme-legacy);
  @include mat.sort-theme(light.$sbam-light-theme-legacy);
  @include mat.toolbar-theme(light.$sbam-light-theme-legacy);
  @include mat.tree-theme(light.$sbam-light-theme-legacy);
}

.sb-app-background,
.sb-app-background sb-app-layout > .mat-drawer-container {
  @include mixins.base-colors(variables.$text-light, variables.$app-background-light);
}

@include components.theme(light.$sbam-light-theme-legacy, typography.$sbam-typography);
@include components.styles();

@import "reboot";

@import "utils";

/**
 * CSS variables (both mdc overrides and custom)
 */
:root {
  --mdc-shape-small: #{variables.$base-radius * 2};
  --mdc-shape-medium: #{variables.$card-radius};
  --mdc-protected-button-container-elevation: #{variables.$elevation-light};
  --mdc-protected-button-hover-container-elevation: #{variables.$elevation-medium-light};
}

// todo move to components
figure {
  background-color: variables.$surface-variant-light;
}

.mat-mdc-autocomplete-panel .mat-mdc-option {
  & > .mat-icon {
    margin-right: 16px;
  }

  .mat-icon {
    margin: 0;
  }

  & > .mdc-list-item__primary-text {
    width: 100%;
  }
}
