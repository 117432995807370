@use "sass:map";
@use "@angular/material" as mat;
@use "variables" as var;
@import "mixins";

@mixin styles() {
  .mat-datepicker-popup {
    .mat-datepicker-content {
      border-radius: 16px;
    }
  }

  .mat-calendar {
    // > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical)
    .mat-calendar-body-cell {
      &:not(.mat-calendar-body-disabled):hover {
        //@include opaque-ripple($primary, 0.08);
      }
      .mat-calendar-body-cell-content {
        &.mat-calendar-body-today {
        }
      }
    }
  }
}

@mixin color($config-or-theme) {
  $config: mat.get-color-config($config-or-theme);
  $is-dark-theme: map.get($config, is-dark);

  $background-color: if($is-dark-theme, var.$dialog-background-dark, var.$dialog-background-light);
  $primary: if($is-dark-theme, var.$primary-dark, var.$primary-light);

  .mat-datepicker-content {
    background-color: $background-color;
  }

  .mat-calendar {
    // > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical)
    .mat-calendar-body-cell {
      &:not(.mat-calendar-body-disabled):hover {
        //@include opaque-ripple($primary, 0.08);
      }
      .mat-calendar-body-cell-content {
        &.mat-calendar-body-today:not(.mat-calendar-body-selected) {
          color: $primary;
          border-color: $primary;
        }
      }
    }

    .mat-calendar-body-in-range::before {
      background: if(
        $is-dark-theme,
        var.$datepicker-in-range-background-dark,
        var.$datepicker-in-range-background-light
      );
    }
  }
}
