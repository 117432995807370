@use 'sass:map';
@use '@angular/material' as mat;
@use 'variables' as var;
@import 'mixins';

@mixin color($config-or-theme) {
  $config: mat.get-color-config($config-or-theme);
  $is-dark-theme: map.get($config, is-dark);
  $hover-color: if($is-dark-theme, var.$list-item-hover-background-dark, var.$list-item-hover-background-light);

  .mat-mdc-list-item {
    --mdc-list-list-item-one-line-container-height: auto;
  }

  .mat-mdc-selection-list {
    --mdc-list-list-item-hover-state-layer-color: #{$hover-color};
    --mdc-list-list-item-hover-state-layer-opacity: 1;
    --mdc-list-list-item-focus-state-layer-color: #{$hover-color};
    --mdc-list-list-item-focus-state-layer-opacity: 1;

    .mdc-list-item__content {
      z-index: 1;
    }

  }
}
