//
// popover.scss
// Extended from Bootstrap
//

//
// Bootstrap Overrides =====================================
//

.popover {
  box-shadow: $box-shadow;
  padding: $popover-padding-y $popover-padding-x;
  max-width: 100%;
  border-color: $gray-400;

  &:hover {
    display: block !important;
  }
}

.popover-header {
  margin-bottom: $popover-header-margin-bottom;
  border-bottom: 0;
}

.popover-body-label {
  margin-left: 0.25rem;
}

.popover-body-value {
  margin-left: 0.25rem;
}

.popover-body-indicator {
  display: inline-block;
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
}
