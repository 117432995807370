//
// alerts
// Extended from Bootstrap
//

//
// Bootstrap Overrides =====================================
//

// Allow for a text-decoration since links are the same color as the alert text.

.alert-link {
  text-decoration: $alert-link-text-decoration;
}

.alert {
  font-weight: 500;
}
