@use 'sass:map';
@use '@angular/material' as mat;
@use 'variables' as var;
@import 'mixins';

@mixin styles() {
  .mdc-dialog.mat-mdc-dialog-container {
    --mdc-dialog-container-elevation: none;
    --mdc-dialog-container-shape: #{var.$dialog-radius};
    padding: 0 var.$base-spacer * 6;

    .mat-mdc-dialog-title {
      padding: var.$dialog-header-padding;

      &:before {
        content: none; // reset title height
      }
    }

    .mat-mdc-dialog-content {
      padding: var.$dialog-content-padding;
    }

    .mat-mdc-dialog-actions {
      display: flex;
      gap: 0.5rem;
      padding: var.$dialog-actions-padding;
    }

  }
}

@mixin color($config-or-theme) {
  $config: mat.get-color-config($config-or-theme);
  $is-dark-theme: map.get($config, is-dark);
  $border-color: if($is-dark-theme, var.$card-border-dark, var.$card-border-light);

  .mdc-dialog.mat-mdc-dialog-container {
    --mdc-dialog-container-color: #{if($is-dark-theme, var.$dialog-background-dark, var.$dialog-background-light)};

    .mat-mdc-dialog-title {
      color: if($is-dark-theme, var.$text-dark, var.$text-light);
    }

    .mat-mdc-dialog-content {
      color: if($is-dark-theme, var.$text-content-dark, var.$text-content-light);
    }
  }

}

@mixin typography($config) {
  .mdc-dialog.mat-mdc-dialog-container {

    .mat-mdc-dialog-title {
      font-size: var.$dialog-header-font-size;
      font-weight: var.$dialog-header-font-weight;
      line-height: var.$dialog-header-line-height;
    }
  }

  .mat-mdc-dialog-content {
    @include mat.typography-level($config, button);
    font-weight: var.$card-subtitle-font-weight;
  }
}
