//
// pagination.scss
// Extended from Bootstrap
//

//
// Bootstrap Overrides ===================================
//

.page-item {
  &:first-child {
    .page-link {
      margin-left: 0;
      @include border-left-radius($pagination-border-radius);
    }
  }

  &:last-child {
    .page-link {
      @include border-right-radius($pagination-border-radius);
    }
  }
}
