@use 'sass:map';
@use '@angular/material' as mat;
@use 'variables' as var;
@import 'mixins';

@mixin styles() {
  .mdc-form-field {
    max-width: 100%;
    overflow: hidden;
  }

  .mat-mdc-checkbox label {
    max-width: 100%;
    overflow: hidden;
  }
}


@mixin color($config-or-theme) {
  $config: mat.get-color-config($config-or-theme);
  $is-dark-theme: map.get($config, is-dark);

  $filled-background: if($is-dark-theme, var.$form-field-filled-background-dark, var.$form-field-filled-background-light);
  $filled-background-disabled: if($is-dark-theme, var.$form-field-filled-background-disabled-dark, var.$form-field-filled-background-disabled-light);
  $filled-text-disabled: if($is-dark-theme, var.$form-field-filled-text-disabled-dark, var.$form-field-filled-text-disabled-light);
  $primary: if($is-dark-theme, var.$primary-dark, var.$primary-light);

  .mat-mdc-form-field {
    label {
      user-select: none;
    }

    // Disabled
    --mdc-filled-text-field-disabled-container-color: #{$filled-background-disabled};

  }

  .mdc-text-field {
    &.mdc-text-field--filled {
      --mdc-filled-text-field-container-shape: #{var.$form-field-radius};
      --mdc-filled-text-field-disabled-active-indicator-color: #{$filled-text-disabled};

      &:not(.mdc-text-field--disabled) {
        --mdc-filled-text-field-container-color: #{$filled-background};
      }
    }

    &.mdc-text-field--outlined {
      --mdc-outlined-text-field-container-shape: #{var.$form-field-radius};
    }
  }


  .mat-mdc-form-field:not(.mat-form-field-disabled) {
    .mat-mdc-form-field__input, .mat-mdc-form-field__input {
      color: if($is-dark-theme, var.$text-dark, var.$text-light);

      &::placeholder {
        color: if($is-dark-theme, var.$text-content-dark, var.$text-content-light);
      }
    }

    &:focus .mat-mdc-form-field-focus-overlay {
      background-color: $primary;
      opacity: 0.08;
    }
  }

}

@mixin typography($config) {
}
