//
// tooltip
// Extended from Bootstrap
//

//
// Bootstrap Overrides =====================================
//

.tooltip-inner {
  font-weight: bold;
}
