//
// main-content.scss
// Dashkit component
//
@use 'sass:math';

// Main content
//
// General styles

.main-content {
  padding-top: $topbar-height-mobile;
  padding-bottom: 5rem;
  transition: 0.5s;

  @include media-breakpoint-up(md) {
    padding-top: 0;
    padding-bottom: 0.25rem;
  }

  .container-fluid {
    @include media-breakpoint-up(xl) {
      padding-left: ($main-content-padding-x + math.div($grid-gutter-width, 2)) !important;
      padding-right: ($main-content-padding-x + math.div($grid-gutter-width, 2)) !important;
    }
  }
}

// Main content offset
//
// Offsets the main content depending on the sidebar positioning

.navbar-vertical.navbar-expand {
  @each $breakpoint, $dimension in $grid-breakpoints {
    &-#{$breakpoint} {
      @include media-breakpoint-up(#{$breakpoint}) {
        // Left

        &.fixed-left:not([style*='display: none']) ~ .main-content {
          margin-left: $navbar-vertical-width;
        }

        // Right

        &.fixed-right:not([style*='display: none']) ~ .main-content {
          margin-right: $navbar-vertical-width;
        }
      }
    }
  }
}
