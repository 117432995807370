//
// type.scss
// Extended from Bootstrap
//

//
// Bootstrap Overrides =====================================
//

h1,
.h1 {
  margin-bottom: $headings-margin-bottom;
  font-weight: 600;

  @include media-breakpoint-up(md) {
    font-size: $h1-font-size;
  }
}
h2,
.h2 {
  margin-bottom: $headings-margin-bottom;
}
h3,
.h3 {
  margin-bottom: ($headings-margin-bottom * 0.75);
}
h4,
.h4 {
  margin-bottom: ($headings-margin-bottom * 0.5);
}
h5,
.h5 {
  margin-bottom: ($headings-margin-bottom * 0.5);
}
h6,
.h6 {
  margin-bottom: ($headings-margin-bottom * 0.5);
}

// Links

h1,
h2,
h3,
h4,
h5,
h6 {
  > a {
    color: inherit;
  }
}

// Type display classes

.display-1,
.display-2,
.display-3,
.display-4 {
  letter-spacing: $display-letter-spacing;
}

// Headings

h1,
h2,
h3,
h4,
h5,
h6 {
  letter-spacing: $headings-letter-spacing;
}
h6.text-uppercase {
  letter-spacing: 0.08em;
}

// Bold, strong

b,
strong {
  font-weight: $font-weight-bold;
}

// Links, buttons
//
// Removes focus outline

a,
button {
  &:focus {
    outline: none !important;
  }
}

//
// Dashkit =====================================
