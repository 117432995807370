@use 'sass:map';
@use '@angular/material' as mat;
@use 'variables';
@import 'mixins';

@mixin styles() {

  div.mat-mdc-autocomplete-panel.mdc-menu-surface,
  div.mat-mdc-select-panel.mdc-menu-surface,
  div.mat-mdc-menu-panel {
    --mat-menu-container-shape: #{variables.$menu-panel-radius};
    border-radius: variables.$menu-panel-radius;
    max-width: none;
  }
}

@mixin color($config-or-theme) {
  $config: mat.get-color-config($config-or-theme);
  $is-dark-theme: map.get($config, is-dark);

  $menu-panel-background: if($is-dark-theme, variables.$surface-container-dark, variables.$surface-container-light);

  .mat-mdc-menu-panel {
    --mat-menu-container-color: #{$menu-panel-background};

    &.mat-elevation-z8,
    .mat-mdc-elevation-specific.mat-elevation-z8 {
      box-shadow: if($is-dark-theme, variables.$elevation-medium-dark, variables.$elevation-medium-light);
    }

    .mat-mdc-menu-item[disabled] {
      color: if($is-dark-theme, variables.$text-disabled-dark, variables.$text-disabled-light);
      opacity: 1;
    }
  }

  .mat-mdc-select-panel {
    --mat-select-panel-background-color: #{$menu-panel-background};

    &.mat-elevation-z8,
    .mat-mdc-elevation-specific.mat-elevation-z8 {
      box-shadow: if($is-dark-theme, variables.$elevation-medium-dark, variables.$elevation-medium-light);
    }

    .mat-mdc-menu-item[disabled] {
      color: if($is-dark-theme, variables.$text-disabled-dark, variables.$text-disabled-light);
      opacity: 1;
    }
  }

  .mat-mdc-autocomplete-panel {
    --mat-autocomplete-background-color: #{$menu-panel-background};

    &.mat-elevation-z8,
    .mat-mdc-elevation-specific.mat-elevation-z8 {
      box-shadow: if($is-dark-theme, variables.$elevation-medium-dark, variables.$elevation-medium-light);
    }

    .mat-mdc-list-item[disabled] {
      color: if($is-dark-theme, variables.$text-disabled-dark, variables.$text-disabled-light);
      opacity: 1;
    }
  }
}
