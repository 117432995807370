//
// avatar.scss
// Dashkit component
//
@use 'sass:math';

// General

.channel__type {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 16px;
  z-index: 3;
  background-color: white;
  padding: 0.05rem;

  &.ig {
    border-radius: 0.3rem;
  }

  &.fb {
    border-radius: 50%;
  }

  &.fb-ads {
    border-radius: 0.5rem;
    padding: 0.1rem;
    width: 17px;
    border: 1px solid grey;
  }

  &.linkedin-organization {
    border-radius: 0.2rem;
    padding: 1px;
    width: 17px;
  }
}

.channel__add {
  position: relative;
  display: inline-block;
  border: 2px solid $sbam;
  width: $avatar-size-base;
  height: $avatar-size-base;
  font-size: math.div($avatar-size-base, 3);
}

.channel {
  position: relative;
  display: inline-block;
  width: $avatar-size-base;
  height: $avatar-size-base;
  font-size: math.div($avatar-size-base, 3);

  &:not(.active):not(:hover) {
    filter: opacity(0.5);
  }

  // Loads mask images so they don't lag on hover
  &:after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    background-image: url(#{$path-to-img}/masks/channel/channel-group-left.svg),
      url(#{$path-to-img}/masks/channel/channel-group-both.svg),
      url(#{$path-to-img}/masks/channel/channel-group-right.svg);
  }
}

.channel-img {
  width: 100%;
  height: 100%;
  min-width: 100%;
  min-height: 100%;
  object-fit: cover;
  border: 2px solid $sbam;
}

.channel-title {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: $avatar-title-bg;
  color: $avatar-title-color;
}

// Status

.channel-online,
.channel-offline {
  &::before {
    content: '';
    position: absolute;
    bottom: 5%;
    right: 5%;
    width: 20%;
    height: 20%;
    border-radius: 50%;
  }
}

.channel-online::before {
  background-color: $success;
}

.channel-offline::before {
  background-color: $gray-500;
}

// Sizing

.channel-xs {
  width: $avatar-size-xs;
  height: $avatar-size-xs;
  font-size: math.div($avatar-size-xs, 3);
}

.channel-sm {
  width: $avatar-size-sm;
  height: $avatar-size-sm;
  font-size: math.div($avatar-size-sm, 3);
}

.channel-lg {
  width: $avatar-size-lg;
  height: $avatar-size-lg;
  font-size: math.div($avatar-size-lg, 3);
}

.channel-xl {
  width: $avatar-size-xl;
  height: $avatar-size-xl;
  font-size: math.div($avatar-size-xl, 3);
}

.channel-xxl {
  width: $avatar-size-xl;
  height: $avatar-size-xl;
  font-size: math.div($avatar-size-xl, 3);

  @include media-breakpoint-up(md) {
    width: $avatar-size-xxl;
    height: $avatar-size-xxl;
    font-size: math.div($avatar-size-xxl, 3);
  }
}

// Ratio

.channel.channel-4by3 {
  width: $avatar-size-base * math.div(4, 3);
}

.channel-xs.channel-4by3 {
  width: $avatar-size-xs * math.div(4, 3);
}

.channel-sm.channel-4by3 {
  width: $avatar-size-sm * math.div(4, 3);
}

.channel-lg.channel-4by3 {
  width: $avatar-size-lg * math.div(4, 3);
}

.channel-xl.channel-4by3 {
  width: $avatar-size-xl * math.div(4, 3);
}

.channel-xxl.channel-4by3 {
  width: $avatar-size-xxl * math.div(4, 3);
}

// Group

.channel-group {
  display: inline-flex;

  // Shift every next avatar left

  .channel + .channel {
    margin-left: -(math.div($avatar-size-base, 4));
  }

  .channel-xs + .channel-xs {
    margin-left: -(math.div($avatar-size-xs, 4));
  }

  .channel-sm + .channel-sm {
    margin-left: -(math.div($avatar-size-sm, 4));
  }

  .channel-lg + .channel-lg {
    margin-left: -(math.div($avatar-size-lg, 4));
  }

  .channel-xl + .channel-xl {
    margin-left: -(math.div($avatar-size-xl, 4));
  }

  .channel-xxl + .channel-xxl {
    margin-left: -(math.div($avatar-size-xxl, 4));
  }

  // Added temporarily for social account selection
  .channel {
    mask-size: 100% 100%;
  }

  .channel.left {
    mask-image: url(#{$path-to-img}/masks/channel/channel-group-left.svg);
  }

  .channel.right {
    mask-image: url(#{$path-to-img}/masks/channel/channel-group-right.svg);
  }

  .channel.active {
    mask-image: none;
    z-index: 1;

    .channel-img {
      border: 2px solid $yellow;
    }
  }

  .channel.left:hover {
    + .channel {
      mask-image: url(#{$path-to-img}/masks/channel/channel-group-right.svg);
    }

    + .channel.left {
      mask-image: url(#{$path-to-img}/masks/channel/channel-group-both.svg);
    }
  }

  // End temporarily social account selection

  // Bring an avatar to front on hover

  .channel:hover {
    mask-image: none;
    z-index: 2; // changed for social account selection
  }
}
