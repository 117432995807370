@use 'sass:color';
@use 'sass:map';
@use '@angular/material' as mat;
@use 'variables';
@import 'mixins';

@mixin color($config-or-theme) {
  $config: mat.get-color-config($config-or-theme);

  $foreground: map.get($config, foreground);
  $is-dark-theme: map.get($config, is-dark);
  $disabled-color: if($is-dark-theme, variables.$text-disabled-dark, variables.$text-disabled-light);
  $disabled-background: if($is-dark-theme, variables.$button-disabled-dark, variables.$button-disabled-light);
  $border-color: if($is-dark-theme, variables.$button-border-dark, variables.$button-border-light);

}

@mixin typography($config) {

  sb-sidebar mat-nav-list.sidebar .mat-mdc-list-item .mdc-list-item__primary-text {
      @include mat.typography-level($config, button);
  }
}
