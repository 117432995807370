@use 'sass:map';
@use '@angular/material' as mat;
@use 'variables';
@import 'mixins';

@mixin styles() {

  /* TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version. */
  mat-card.mat-mdc-card {
    border-radius: variables.$card-radius;

    &.mat-mdc-card-outlined {
      border-radius: variables.$card-radius;
    }

    .mat-mdc-card-header {
      padding: variables.$card-header-padding;

      .mat-mdc-card-header-text {
        width: 100%;
      }

      .mat-mdc-card-subtitle {
        margin-top: variables.$card-subtitle-margin-top;
      }
    }

    .mat-mdc-card-content {
      display: flex;
      flex-direction: column;
      height: 100%;
      padding: variables.$card-content-padding;
    }

    .mat-mdc-card-actions {
      display: flex;
      gap: 0.5rem;
      padding: variables.$card-content-padding;
      margin-top: auto;
      min-height: auto;
    }

  }
}

@mixin color($config-or-theme) {
  $config: mat.get-color-config($config-or-theme);
  $is-dark-theme: map.get($config, is-dark);
  $border-color: if($is-dark-theme, variables.$card-border-dark, variables.$card-border-light);

  .mat-mdc-card {

    --mdc-elevated-card-container-elevation: #{if($is-dark-theme, variables.$elevation-medium-dark, variables.$elevation-medium-light)};
    --mdc-elevated-card-container-color: #{if($is-dark-theme, variables.$surface-container-low-dark, variables.$surface-container-low-light)};

    &.mat-mdc-card-outlined {
      border-color: $border-color;
      box-shadow: none;
      background: if($is-dark-theme, variables.$surface-dark, variables.$surface-light);
    }

    .mat-mdc-card-header {

      .mat-mdc-card-title,
      .mat-mdc-card-subtitle {
        color: if($is-dark-theme, variables.$text-dark, variables.$text-light);
      }
    }

    .mat-mdc-card-content {
      color: if($is-dark-theme, variables.$card-content-text-dark, variables.$card-content-text-light);
    }

    &.flat {
      border-color: if($is-dark-theme, variables.$card-flat-dark, variables.$card-flat-light);
      background: if($is-dark-theme, variables.$card-flat-dark, variables.$card-flat-light);
    }

    &.primary {
      border-color: if($is-dark-theme, variables.$primary-dark, variables.$primary-light);
      background: if($is-dark-theme, variables.$primary-dark, variables.$primary-light);

      .mat-mdc-card-header {

        .mat-mdc-card-title,
        .mat-mdc-card-subtitle {
          color: if($is-dark-theme, variables.$text-light, variables.$text-dark);
        }
      }

      .mat-mdc-card-content {
        color: if($is-dark-theme, variables.$text-light, variables.$text-dark);
      }
    }


  }

}

@mixin typography($config) {
  .mat-mdc-card {

    .mat-mdc-card-header {

      .mat-mdc-card-title {
        @include mat.typography-level($config, body-2);
      }

      .mat-mdc-card-subtitle {
        @include mat.typography-level($config, button);
        font-weight: variables.$card-subtitle-font-weight;
      }
    }

    .mat-mdc-card-content {
      @include mat.typography-level($config, button);
      font-weight: variables.$card-subtitle-font-weight;
    }
  }
}
