@use 'sass:map';
@use '@angular/material' as mat;
@use 'variables';
@use 'components/button-theme' as button-theme;
@import 'mixins';

@mixin styles() {}

@mixin color($config-or-theme) {
  $config: mat.get-color-config($config-or-theme);
  $is-dark-theme: map.get($config, is-dark);
  $action-color: if($is-dark-theme, variables.$snackbar-action-text-dark, variables.$snackbar-action-text-light);

  .mat-mdc-snack-bar-container {
    --mdc-snackbar-container-color: #{if($is-dark-theme, variables.$snackbar-background-dark, variables.$snackbar-background-light)};

    .mat-mdc-snack-bar-action {
      --mat-snack-bar-button-color: #{$action-color};

      &:hover,
      &:focus {
        @include button-theme.opaque-ripple($action-color, 0.08)
      }
    }
  }
}

@mixin typography($config) {

  .mat-mdc-snack-bar-container {
    .mat-mdc-simple-snack-bar {
      .mdc-snackbar__label {
        @include mat.typography-level($config, button);
        font-weight: variables.$snackbar-label-font-weight;
      }
    }
  }
}
