@use 'sass:map';
@use '@angular/material' as mat;
@use 'variables';
@import 'mixins';

@mixin color($config-or-theme) {
  $config: mat.get-color-config($config-or-theme);
  $is-dark-theme: map.get($config, is-dark);
  $background-color: if($is-dark-theme, variables.$surface-dark, variables.$surface-light);
  $text-color: if($is-dark-theme, variables.$text-dark, variables.$text-light);

  sb-view {
    main {
      > .toolbar,
      > .content {
        @include base-colors($text-color, $background-color);
      }
    }
  }

  .bg-surface {
    @include base-colors($text-color, $background-color);
  }
}
