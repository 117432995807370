//
// forms.scss
// Extended from Bootstrap
//
@use 'sass:math';

//
// Bootstrap Overrides ===================================
//

// Help text

.form-text {
  margin-top: 0;
  margin-bottom: $label-margin-bottom;
}

// Form validation

.was-validated .form-control:valid:focus,
.form-control.is-valid:focus,
.was-validated .form-control:invalid:focus,
.form-control.is-invalid:focus {
  box-shadow: none;
}

//
// Dashkit ===================================
//

// Form control flush
//
// Removes borders and paddings from inputs and text areas

.form-control-flush {
  padding: 0;
  border-width: 0;
  background-color: transparent;

  &:focus {
    background-color: transparent;
  }
}

// Form control rounded
//
// Makes input border radius round

.form-control-rounded {
  border-radius: 20rem;
}

// Input group merge
//
// Makes input group text and form control visually the same element

.input-group.input-group-merge {
  .input-group-text.focus {
    border-color: $input-focus-border-color;
  }

  > .form-floating > .form-control,
  > .form-control {
    border-radius: $input-border-radius;

    &:focus {
      box-shadow: none;

      ~ [class*='input-group'] {
        > .input-group-text {
          border-color: $input-focus-border-color;
        }
      }
    }
  }

  > .form-control.is-valid ~ [class*='input-group'] > .input-group-text {
    border-color: $success;
  }

  > .form-control.is-invalid ~ [class*='input-group'] > .input-group-text {
    border-color: $danger;
  }

  > .form-control-prepended {
    padding-left: math.div($input-padding-x, 2);
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    border-left-width: 0;
  }

  > .form-floating > .form-control-appended,
  > .form-control-appended {
    padding-right: math.div($input-padding-x, 2);
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    border-right-width: 0;
  }

  > .input-group-prepend {
    order: -1; // prepend always goes first

    > .input-group-text {
      padding-right: math.div($input-padding-x, 2);
      border-top-left-radius: $input-border-radius;
      border-bottom-left-radius: $input-border-radius;
      border-right-width: 0;
      transition: $input-transition;
    }
  }

  > .input-group-append {
    > .input-group-text {
      padding-left: math.div($input-padding-x, 2);
      border-top-right-radius: $input-border-radius;
      border-bottom-right-radius: $input-border-radius;
      border-left-width: 0;
      transition: $input-transition;
    }
  }
}

.input-group-lg.input-group-merge {
  > .form-control {
    border-radius: $input-border-radius-lg;
  }

  > .form-control-prepended {
    padding-left: math.div($input-padding-x-lg, 2);
  }

  > .form-control-appended {
    padding-right: math.div($input-padding-x-lg, 2);
  }

  > .input-group-prepend {
    > .input-group-text {
      padding-right: math.div($input-padding-x-lg, 2);
      border-top-left-radius: $input-border-radius-lg;
      border-bottom-left-radius: $input-border-radius-lg;
    }
  }

  > .input-group-append {
    > .input-group-text {
      padding-left: math.div($input-padding-x-lg, 2);
      border-top-right-radius: $input-border-radius-lg;
      border-bottom-right-radius: $input-border-radius-lg;
    }
  }
}

.input-group-sm.input-group-merge {
  > .form-control {
    border-radius: $input-border-radius-sm;
  }

  > .form-control-prepended {
    padding-left: math.div($input-padding-x-sm, 2);
  }

  > .form-control-appended {
    padding-right: math.div($input-padding-x-sm, 2);
  }

  > .input-group-prepend {
    > .input-group-text {
      padding-right: math.div($input-padding-x-sm, 2);
      border-top-left-radius: $input-border-radius-sm;
      border-bottom-left-radius: $input-border-radius-sm;
    }
  }

  > .input-group-append {
    > .input-group-text {
      padding-left: math.div($input-padding-x-sm, 2);
      border-top-right-radius: $input-border-radius-sm;
      border-bottom-right-radius: $input-border-radius-sm;
    }
  }
}

.input-group-rounded.input-group-merge {
  > .form-control {
    border-radius: 20rem;
  }

  > .input-group-prepend {
    > .input-group-text {
      border-top-left-radius: 20rem;
      border-bottom-left-radius: 20rem;
    }
  }

  > .input-group-append {
    > .input-group-text {
      border-top-right-radius: 20rem;
      border-bottom-right-radius: 20rem;
    }
  }
}

.input-group-flush {
  > .form-control {
    padding: 0;
    border-width: 0;
    background-color: transparent;
  }

  > .input-group-prepend,
  > .input-group-append {
    > .input-group-text {
      padding: 0;
      border-width: 0;
      background-color: transparent;
    }
  }
}

.form-floating {
  position: relative;

  > .form-control,
  > .form-select {
    height: $form-floating-height;
    line-height: $form-floating-line-height;
  }

  > label {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%; // allow textareas
    padding: $form-floating-padding-y $form-floating-padding-x;
    pointer-events: none;
    border: $input-border-width solid transparent; // Required for aligning label's text with the input as it affects inner box model
    transform-origin: 0 0;
    @include transition($form-floating-transition);
  }

  // stylelint-disable no-duplicate-selectors
  > .form-control {
    padding: $form-floating-padding-y $form-floating-padding-x;

    &::placeholder {
      color: transparent;
    }

    &:focus,
    &:not(:placeholder-shown) {
      padding-top: $form-floating-input-padding-t;
      padding-bottom: $form-floating-input-padding-b;
    }
    // Duplicated because `:-webkit-autofill` invalidates other selectors when grouped
    &:-webkit-autofill {
      padding-top: $form-floating-input-padding-t;
      padding-bottom: $form-floating-input-padding-b;
    }
  }

  > .form-select {
    padding-top: $form-floating-input-padding-t;
    padding-bottom: $form-floating-input-padding-b;
  }

  > .form-control:focus,
  > .form-control:not(:placeholder-shown),
  > .form-select {
    ~ label {
      opacity: $form-floating-label-opacity;
      transform: $form-floating-label-transform;
    }
  }
  // Duplicated because `:-webkit-autofill` invalidates other selectors when grouped
  > .form-control:-webkit-autofill {
    ~ label {
      opacity: $form-floating-label-opacity;
      transform: $form-floating-label-transform;
    }
  }
  // stylelint-enable no-duplicate-selectors
}
