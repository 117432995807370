@use 'sass:color';
@use 'sass:map';
@use '@angular/material' as mat;
@use 'variables' as var;
@import 'mixins';

@mixin styles() {

  .alert {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    border-radius: var.$alert-radius;

    >.content {
      width: 100%;
      font-weight: 500;
      padding: var.$alert-padding-y var.$alert-padding-x;
      display: flex;
      align-items: center;
      gap: 1rem;


      a {
        font-weight: 600;
        text-decoration: underline;
      }
    }
  }
}

@mixin alert-colors($background, $foreground) {
  background-color: $background;
  color: $foreground;

  mat-icon.mat-icon {
    //--mdc-icon-button-icon-color: $foreground;
    color: $foreground;
  }
}

@mixin color($config-or-theme) {
  $config: mat.get-color-config($config-or-theme);

  $is-dark-theme: map.get($config, is-dark);

  .alert {
    &.alert-success {
      $background: if($is-dark-theme, var.$alert-success-background-dark, var.$alert-success-background-light);
      $foreground: if($is-dark-theme, var.$alert-success-text-dark, var.$alert-success-text-light);
      @include alert-colors($background, $foreground);
    }

    &.alert-warning {
      $background: if($is-dark-theme, var.$alert-warning-background-dark, var.$alert-warning-background-light);
      $foreground: if($is-dark-theme, var.$alert-warning-text-dark, var.$alert-warning-text-light);
      @include alert-colors($background, $foreground);
    }

    &.alert-error {
      $background: if($is-dark-theme, var.$alert-error-background-dark, var.$alert-error-background-light);
      $foreground: if($is-dark-theme, var.$alert-error-text-dark, var.$alert-error-text-light);
      @include alert-colors($background, $foreground);
    }

    &.alert-secondary {
      $background: if($is-dark-theme, var.$alert-secondary-background-dark, var.$alert-secondary-background-light);
      $foreground: if($is-dark-theme, var.$alert-secondary-text-dark, var.$alert-secondary-text-light);
      @include alert-colors($background, $foreground);
    }

    &.alert-primary {
      $background: if($is-dark-theme, var.$alert-primary-background-dark, var.$alert-primary-background-light);
      $foreground: if($is-dark-theme, var.$alert-primary-text-dark, var.$alert-primary-text-light);
      @include alert-colors($background, $foreground);
    }

    &.alert-neutral {
      $background: if($is-dark-theme, var.$alert-neutral-background-dark, var.$alert-neutral-background-light);
      $foreground: if($is-dark-theme, var.$alert-neutral-text-dark, var.$alert-neutral-text-light);
      @include alert-colors($background, $foreground);
    }

    >.content a {
      color: inherit;
    }

  }


}
