@use "variables" as var;

mat-icon.mat-icon {
  display: inline-flex;
  align-items: center;
  flex-shrink: 0;
  justify-content: center;
  color: var.$icon-color-light;
  height: auto;
  aspect-ratio: 1 / 1;

  svg {
    height: 100%;
    width: 100%;
  }

  &.svg-baseline svg {
    top: 0.125em;
    position: relative;
  }

  // Use the following code, to create use custom color for mat-icon.
  // Each string passed in the color prop of mat-icon will generate a class with mat- prefix followed by the string.
  // For example, if you pass <mat-icon color="foo"/>
  // then the class mat-foo will be added to mat-icon -> <mat-icon class="mat-icon mat-foo"/>.
  /*
  &.mat-<color-nam> {
  color: var.$icon-color-<color-name>;
    }
  }
  */
  &.mat-inherit {
    color: inherit;
  }
}
