@use "sass:color";
@use "sass:map";
@use "@angular/material" as mat;
@use "variables" as var;
@import "mixins";

@mixin disabled($color, $background-color: null, $border-color: null) {
  &:disabled {
    color: $color;

    @if $background-color !=null {
      background-color: $background-color;
    }
  }
}

@mixin opaque-ripple($color, $opacity) {
  .mat-mdc-button-persistent-ripple::before {
    background-color: $color;
    opacity: $opacity;
  }
}

@mixin styles {
  .fullscreen-fab {
    z-index: 2;
    position: fixed;
    bottom: 16px;
    right: 16px;
  }
}

@mixin color($config-or-theme) {
  $config: mat.get-color-config($config-or-theme);

  $foreground: map.get($config, foreground);
  $is-dark-theme: map.get($config, is-dark);
  $disabled-color: if($is-dark-theme, var.$text-disabled-dark, var.$text-disabled-light);
  $disabled-background: if($is-dark-theme, var.$button-disabled-dark, var.$button-disabled-light);
  $border-color: if($is-dark-theme, var.$button-border-dark, var.$button-border-light);
  $primary: if($is-dark-theme, var.$primary-dark, var.$primary-light);

  $tonal-background: if($is-dark-theme, var.$button-background-tonal-dark, var.$button-background-tonal-light);
  $tonal-color: if($is-dark-theme, var.$button-color-tonal-dark, var.$button-color-tonal-light);

  $square-background-hover: if($is-dark-theme,
      var.$button-background-square-hover-dark,
      var.$button-background-square-hover-light );
  $square-background: if($is-dark-theme, var.$button-background-square-dark, var.$button-background-square-light);
  $square-color: if($is-dark-theme, var.$button-color-square-dark, var.$button-color-square-light);

  $menu-background: if($is-dark-theme, var.$button-background-menu-dark, var.$button-background-menu-light);
  $menu-background-hover: if($is-dark-theme,
      var.$button-background-menu-hover-dark,
      var.$button-background-menu-hover-light );
  $menu-color: if($is-dark-theme, var.$button-color-menu-dark, var.$button-color-menu-light);

  .mdc-button {
    mat-icon.mat-icon {
      color: inherit;
    }

    &.mat-mdc-raised-button:not(:disabled) {
      color: $primary;
      background-color: if($is-dark-theme, var.$button-background-outlined-dark, var.$button-background-outlined-light);

      &:focus {
        @include opaque-ripple($primary, 0.12);
      }
    }

    &:hover {

      &.mat-mdc-raised-button,
      &.mat-mdc-outlined-button,
      &.mat-mdc-button {
        @include opaque-ripple($primary, 0.08);
      }
    }

    &.mat-mdc-raised-button,
    &.mat-mdc-unelevated-button {
      @include disabled($disabled-color, $disabled-background);
    }

    &.mat-mdc-outlined-button {
      &:not(:disabled) {
        border-color: $border-color;
      }

      @include disabled($disabled-color, null, $disabled-color);

      &:focus:not(.square-button):not(.menu-button) {
        border-color: $primary;
      }

      &.square-button {
        color: $square-color;
        background-color: $square-background;

        &:hover {
          @include opaque-ripple($square-background-hover, 0.08);
        }
      }

      &.menu-button {
        color: $menu-color;
        background-color: $menu-background;

        &:hover {
          @include opaque-ripple($menu-background-hover, 0.08);
        }
      }
    }

    // Text buttons
    &.mat-mdc-button {
      @include disabled($disabled-color);
    }

    &.mat-mdc-unelevated-button {

      &:focus,
      &:active {
        --mdc-protected-button-container-elevation: none;
      }
    }

    &.mat-mdc-unelevated-button.tonal-button:not(:disabled) {
      --mdc-filled-button-container-color: #{$tonal-background};
      --mdc-filled-button-label-text-color: #{$tonal-color};
      --mat-mdc-button-ripple-color: null;
      --mat-mdc-button-persistent-ripple-color: #{$tonal-color};

      &:focus,
      &:active {
        @include opaque-ripple($tonal-color, 0.12);
      }

      &:hover {
        @include opaque-ripple($tonal-color, 0.08);
      }
    }
  }

  .mdc-fab.mat-mdc-fab,
  .mdc-fab.mat-mdc-mini-fab {
    box-shadow: var.$fab-elevation-light;

    &:hover {
      box-shadow: var.$fab-elevation-hover-light;
    }

    mat-icon.mat-icon {
      color: var(--mdc-fab-icon-color);
    }

    &.mat-primary {
      --mdc-fab-container-color: #{if(
 $is-dark-theme,
      var.$fab-background-primary-dark,
      var.$fab-background-primary-light )
    }

    ;
    --mdc-fab-icon-color: #{$primary};

    &:hover {
      @include opaque-ripple(var.$fab-background-primary-hover-light, 0.08);
    }

    &:focus {
      @include opaque-ripple(var.$fab-background-primary-hover-light, 0.12);
    }
  }
}
}
