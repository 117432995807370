// src/styles.scss
@tailwind base;
@tailwind components;
@tailwind utilities;

// Sbam theme
@import "scss/sbam";

.old {
  // Bootstrap functions
  @import "../node_modules/bootstrap/scss/functions";

  // Custom variables
  @import "scss/user-variables";

  // Dashkit variables
  @import "scss/dashkit/variables";

  // Bootstrap core
  @import "../node_modules/bootstrap/scss/bootstrap";

  // Dashkit core
  @import "scss/dashkit/dashkit";

  // Sbam legacy theme
  @import "scss/theme/sbam-legacy";

  // Custom core
  @import "scss/social-account";

  @import "scss/user";

  .not-old {
    .mb-8 {
      margin-bottom: 2rem !important;
    }
    .mb-1\.5 {
      margin-bottom: 0.375rem !important;
    }
    mat-icon.icon {
      display: inline-flex;
    }
  }

  figure:not(.not-old *) {
    margin: 0 0 1rem;
  }
}

.mat-typography .old {
  line-height: 1.15;
  font-weight: normal;

  p:not(.not-old *) {
    margin: 0 0 1rem;
  }
}
