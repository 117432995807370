@use 'sass:map';
@use '@angular/material' as mat;
@use 'variables';
@import 'mixins';

@mixin color($config-or-theme) {
  $config: mat.get-color-config($config-or-theme);
  $is-dark-theme: map.get($config, is-dark);
  $thumb-color: if($is-dark-theme, variables.$scrollbar-thumb-dark, variables.$scrollbar-thumb-light);

  // Chromium based and Safari scrollbar

  ::-webkit-scrollbar {
    width: variables.$scrollbar-width;
    height: variables.$scrollbar-height;
  }

  ::-webkit-scrollbar-track {
    border-radius: variables.$scrollbar-radius;
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: variables.$scrollbar-radius;
    background-color: $thumb-color;
  }

  // Firefox only scrollbar

  body {
    scrollbar-width: thin;
    scrollbar-color: $thumb-color transparent;
  }
}
