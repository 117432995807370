//
// user.scss
// Use this to write your custom SCSS
//

// Main content offset
//
// Offsets the main content depending on the sidebar positioning

.sidebar {
  @each $breakpoint, $dimension in $grid-breakpoints {
    &-#{$breakpoint} {
      @include media-breakpoint-up(#{$breakpoint}) {
        // Left

        &.fixed-left:not([style*='display: none']):not(.d-none):not(.d-md-none) ~ .main-content {
          margin-left: $navbar-vertical-width;

          .report-pages-sidebar,
          .fixed-header,
          .ads-container {
            left: $navbar-vertical-width;
          }
        }

        // Right

        &.fixed-right:not([style*='display: none']):not(.d-none):not(.d-md-none) ~ .main-content {
          margin-right: $navbar-vertical-width;
        }
      }
    }
  }
}

a {
  cursor: pointer;
}

.pointer {
  cursor: pointer;
}

.cursor-move {
  cursor: move;
}

.icon-sm,
.icon-sm svg {
  width: 20px !important;
  min-width: 20px;
  height: 20px !important;
  min-height: 20px;
}

.icon-xs,
.icon-xs svg {
  width: 1rem !important;
  min-width: 1rem;
  height: 1rem !important;
  min-height: 1rem;
}

.btn-sm {
  .icon-xs,
  .icon-xs svg {
    width: 0.75rem !important;
    min-width: 0.75rem;
    height: 0.75rem !important;
    min-height: 0.75rem;
  }
}

.icon-xs-block {
  @extend .icon-xs;
  display: block;
  svg {
    display: block;
  }
}

.nav-pills .icon-sm svg,
.nav-pills .icon-xs svg {
  vertical-align: baseline;
}

.icon-link {
  svg {
    vertical-align: top;
  }

  + span {
    margin-left: 0.5rem;
  }
}

.nationality-pills {
  font-size: 0.8rem !important;
}

.nationality-pills > a {
  padding: 0.2rem;
}

.nationality-tab {
  height: 500px;
}

.metric-number {
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 0.8;
  margin-top: 0.3rem;
  margin-bottom: 0.2rem;
}

.text-grey {
  color: #808080;
}

.toast-container ul {
  padding-inline-start: 20px;
}

.theme-sbam {
  border-radius: 0.5rem;
  box-shadow: none;

  .bs-datepicker-head {
    background-color: #06667c;
  }

  .bs-datepicker-body {
    table {
      td span.selected,
      td.selected span,
      td span[class*='select-']:after,
      td[class*='select-'] span:after {
        background-color: #06667c;
      }

      td.week span {
        color: #06667c;
      }

      td.active-week span:hover {
        cursor: pointer;
        background-color: #06667c;
        color: #fff;
        opacity: 0.5;
        transition: 0s;
      }
    }
  }
}

.date-selector-modal,
.range-selector-modal {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
  max-width: fit-content !important;
  margin-left: auto !important;
  margin-right: auto !important;

  .date-selector-modal__title,
  .range-selector-modal__title {
    margin-top: 0.5rem;
    margin-bottom: 0;
    padding-left: 1rem;
    padding-right: 1rem;
    font-size: 1rem;
    text-align: center;
  }

  button.close {
    position: absolute;
    top: 0.5rem;
    right: 1rem;
  }
}

.fit-icon {
  padding: 10%;
}

.fit-icon svg {
  width: 26px;
  height: 26px;
}

@media (min-width: 992px) {
  .toast-container.toast-top-full-width .ngx-toastr {
    width: 40%;
  }
}

.navbar-fixed-top {
  position: fixed;
  top: 0;
  width: 100%;
  height: $topbar-height-mobile;

  #collapse-nav {
    background-color: $navbar-light-bg;
    //border-bottom: 1px solid $navbar-light-border-color;
  }

  &.navbar-dark {
    #collapse-nav {
      background-color: #06667c;
      height: 100vh;
    }
  }

  @include media-breakpoint-up(md) {
    & #collapse-nav {
      border-bottom: none;
    }
    height: auto;
  }
}

.text-highlight {
  font-weight: bold;
  background: $sbam;
  color: #fff;
  padding: 0 0.25rem;

  &:hover {
    color: $yellow;
  }
}

.news-box {
  max-height: 300px;
  //background-color: $gray-100;
}

.btn-fb {
  cursor: pointer;
  border-color: #2c7be5;
  color: #2c7be5;

  &:not(:disabled):not(.disabled):hover,
  &:not(:disabled):not(.disabled).hover {
    background-color: #2c7be5;
    color: #fff;
    border-color: #2c7be5;
  }

  &:focus,
  &.focus {
    box-shadow: $btn-active-box-shadow, 0 0 0 $btn-focus-width rgba(#2c7be5, 0.5);
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active,
  .show > &.dropdown-toggle {
    background: #2c7be5;
    border-color: #2c7be5;

    &:focus {
      // Avoid using mixin so we can pass custom focus shadow properly
      @if $enable-shadows and $btn-active-box-shadow != none {
        box-shadow: $btn-active-box-shadow, 0 0 0 $btn-focus-width rgba(#2c7be5, 0.5);
      } @else {
        box-shadow: 0 0 0 $btn-focus-width rgba(#2c7be5, 0.5);
      }
    }
  }

  &:disabled,
  &.disabled {
    cursor: auto;
    color: #2c7be5;
    border-color: #2c7be5;
    background-color: transparent;
  }
}

.btn-ig {
  cursor: pointer;
  border-color: #dc2743;
  color: #dc2743;

  &:not(:disabled):not(.disabled):hover,
  &:not(:disabled):not(.disabled).hover {
    background: radial-gradient(circle at 30% 107%, #f09433 0%, #e6683c 34%, #dc2743 45%, #cc2366 60%, #bc1888 90%);
    color: #fff;
    border-color: #dc2743;
  }

  &:focus,
  &.focus {
    box-shadow: $btn-active-box-shadow, 0 0 0 $btn-focus-width rgba(#dc2743, 0.5);
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active,
  .show > &.dropdown-toggle {
    background: radial-gradient(circle at 30% 107%, #f09433 0%, #e6683c 34%, #dc2743 45%, #cc2366 60%, #bc1888 90%);
    border-color: #dc2743;

    &:focus {
      // Avoid using mixin so we can pass custom focus shadow properly
      @if $enable-shadows and $btn-active-box-shadow != none {
        box-shadow: $btn-active-box-shadow, 0 0 0 $btn-focus-width rgba(#dc2743, 0.5);
      } @else {
        box-shadow: 0 0 0 $btn-focus-width rgba(#dc2743, 0.5);
      }
    }
  }

  &:disabled,
  &.disabled {
    cursor: auto;
    color: #dc2743;
    border-color: #dc2743;
    background-color: transparent;
  }
}

.modal-dialog-content-detail {
  margin-bottom: 5rem;
}

// Scale up the modal
@include media-breakpoint-up(sm) {
  // Automatically set modal's width for larger viewports
  .modal-dialog-content-detail {
    max-width: 90%;
  }
}

@media (min-width: 1200px) {
  .modal-dialog-content-detail {
    max-width: 1140px;
  }
}

.aspect-ratio-16-9 {
  padding-top: 56.25%;
  background-size: contain;
  background-repeat: no-repeat;
  -moz-background-size: contain;
  background-position: center;
}

.aspect-ratio-9-16 {
  padding-top: 177.77%;
  background-size: contain;
  background-repeat: no-repeat;
  -moz-background-size: contain;
  background-position: center;
}

.aspect-ratio-4-3 {
  padding-top: 75%;
  background-size: contain;
  background-repeat: no-repeat;
  -moz-background-size: contain;
  background-position: center;
}

.aspect-ratio-1-1 {
  padding-top: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  -moz-background-size: contain;
  background-position: center;
}

.metric-field {
  font-size: 1.2rem;
}

.text-strikethrough {
  text-decoration: line-through;
}

.upgrade-to-view {
  //color: ;
}

#ig-stories-overview .card-title,
#ig-account-overview .card-title {
  white-space: nowrap;
}

.go-to-list {
  width: 100%;
  text-align: center;
  position: absolute;
  bottom: 1rem;
}

.spacer {
  height: 0.5rem;
}

.submitting-overlay {
  position: absolute;
  display: flex;
  height: 100%;
  width: 100%;
  z-index: 1040;
  align-items: center;
  background-color: #8f98a266;
  justify-content: center;
  border-radius: 0.5rem;
}

.video-container {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
}

.video-container iframe,
.video-container object,
.video-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.feather-18 {
  height: 18px;
  width: 18px;
}

.date-picker .icon-sm svg,
.range-picker .icon-sm svg {
  vertical-align: text-bottom;
}

.metric-infobox svg {
  height: 1rem;
  width: 1rem;
  vertical-align: baseline;
  margin-left: 0.1rem;
  margin-right: 0.2rem;
}

.card-chart {
  display: flex;
  justify-content: center;
  align-content: center;
  min-height: 300px;
}

// segments bar
.segments-bar {
  display: flex;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 1rem;

  @include media-breakpoint-up(lg) {
    flex-wrap: nowrap;
  }

  .segments {
    max-width: 100%;
    overflow: auto;
    white-space: nowrap;
    margin-right: 0.3rem;
  }

  .btn {
    white-space: nowrap;
    display: block;
    margin: 0.5rem auto auto;

    @include media-breakpoint-up(lg) {
      margin: auto 0 auto 0.5rem;
      display: inline-block;
    }
  }
}

.border-2 {
  border-width: 2px;
}

.locked-icon {
  position: absolute;
  right: 1.2rem;
  stroke: gray;
  padding-bottom: 0.25em;
  font-size: 1.0625rem;
  line-height: 1.40625rem;

  svg {
    vertical-align: baseline;
  }
}

.channel-types {
  display: flex;
  align-items: center;

  img {
    width: 1rem;
    max-width: 100%;
    height: auto;
    margin: 0 0.12rem;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}

.overflow-shadow {
  background: /* Shadow covers */ linear-gradient(white 30%, rgba(255, 255, 255, 0)),
    linear-gradient(rgba(255, 255, 255, 0), white 70%) 0 100%,
    /* Shadows */ radial-gradient(farthest-side at 50% 0, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)),
    radial-gradient(farthest-side at 50% 100%, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)) 0 100%;
  background-repeat: no-repeat;
  background-color: white;
  background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px;

  /* Opera doesn't support this in the shorthand */
  background-attachment: local, local, scroll, scroll;
}

.tox.tox-tinymce.tox-tinymce-inline {
  z-index: 9;
}

.text-loader {
  height: 1.2rem;
  background-color: $light;
  position: relative;
  width: 100%;
  margin-top: 0.75rem;
  border-radius: $border-radius;
  overflow: hidden;
}

.activity {
  position: absolute;
  top: 0;
  left: -35%;
  height: 100%;
  width: 35%;
  background-image: linear-gradient(
    to left,
    transparentize($text-muted, 0.95),
    transparentize($text-muted, 0.75),
    transparentize($text-muted, 0.6),
    transparentize($text-muted, 0.75),
    transparentize($text-muted, 0.95)
  );
  background-image: -moz-linear-gradient(
    left,
    transparentize($text-muted, 0.95),
    transparentize($text-muted, 0.75),
    transparentize($text-muted, 0.6),
    transparentize($text-muted, 0.75),
    transparentize($text-muted, 0.95)
  );
  background-image: -webkit-linear-gradient(
    left,
    transparentize($text-muted, 0.95),
    transparentize($text-muted, 0.75),
    transparentize($text-muted, 0.6),
    transparentize($text-muted, 0.75),
    transparentize($text-muted, 0.95)
  );
  animation: loading 1.2s infinite;
  z-index: 45;
}

@keyframes loading {
  0% {
    left: -35%;
  }
  100% {
    left: 110%;
  }
}

.font-weight-semibold {
  font-weight: 500;
}

.modal-report {
  max-width: 550px;
}
