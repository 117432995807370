//
// toasts.scss
// Extended from Bootstrap
//

.toast {
  position: fixed;
  right: 1rem;
  bottom: 1rem;
  z-index: $zindex-fixed;
}

.toast-container {
  z-index: 99999999999;
}

.toast-top-full-width {
  top: 1.25rem;
}
