//
// user-variables.scss
// Use this to overwrite Bootstrap and Dashkit variables
//

// Example of a variable override to change Dashkit's background color
// Remove the "//" to comment it in and see it in action!
// $body-bg: #12263F;

$black: #12263f !default;

//$font-weight-normal: 500 !default;

$topbar-height: 4rem !default;
$topbar-height-mobile: 4rem !default;

$utility-row-height: 3.5rem;

$box-shadow-lg: 0.5rem 0.5rem 2rem rgba($black, 0.175);

$card-box-shadow: 0rem 0.75rem 1.5rem transparentize($black, 0.9);

$card-border-width: 1px;
$card-border-radius: 0.6rem;
