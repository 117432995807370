@use "sass:map";
@use "themes/config/palette";

@import "mixins";

a {
  color: map.get(palette.$primary, 600);
  transition: color 0.15s ease-in-out;

  &:hover {
    color: map.get(palette.$primary, 700);
  }
}

.text-truncate {
  @include truncate-line();
}

.font-weight-400 {
  font-weight: 400;
}

.font-weight-500 {
  font-weight: 500;
}

.font-weight-600 {
  font-weight: 600;
}

.font-weight-700 {
  font-weight: 700;
}

// Colors
.text-primary {
  color: map.get(palette.$primary, 600);
}

.text-secondary {
  color: map.get(palette.$secondary, 600);
}

.text-success {
  color: map.get(palette.$success, 600);
}

.text-warning {
  color: map.get(palette.$warning, 600);
}

.text-error {
  color: map.get(palette.$error, 600);
}
