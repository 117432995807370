.metric-details-container {
  white-space: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  display: flex;

  .metric-details {
    display: inline-block;
    margin-right: 2rem;

    &.last {
      margin-right: 0;
    }

    .metric-number {
      font-size: 1.8rem;
      font-weight: 500;
      line-height: 0.8;
      margin-top: 0.3rem;
      margin-bottom: 0.2rem;
    }
  }
}
