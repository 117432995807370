@use 'sass:color';
@use 'sass:map';
@use '@angular/material' as mat;
@use 'variables' as var;
@import 'mixins';

@mixin color($config-or-theme) {
  $config: mat.get-color-config($config-or-theme);

  $is-dark-theme: map.get($config, is-dark);
  $text-color: if($is-dark-theme, var.$text-content-dark, var.$text-content-light);
  $border-color: if($is-dark-theme, var.$tabs-border-dark, var.$tabs-border-light);

  .mat-mdc-tab-list {
    .mat-mdc-tab .mdc-tab__text-label {
      color: $text-color;
    }
    border-bottom: 1px solid $border-color;

    .mdc-tab-indicator .mdc-tab-indicator__content--underline {
      border-top-width: 3px;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }
  }
}
