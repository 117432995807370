//
// root.scss
// Extended from Bootstrap
//

html {
  height: 100%;
  scroll-behavior: smooth;
}

body {
  min-height: 100%;
}
