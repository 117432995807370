// themes/dark.scss

@use "sass:map";
@use "@angular/material" as mat;

@use "config/palette";
@use "config/typography";
@use "mixins";
@use "variables";
@use "components";

$sbam-dark-primary: mat.define-palette(palette.$primary, 200, 100, 700, 100);
$sbam-dark-secondary: mat.define-palette(palette.$secondary, 200, 100, 700, 100);
$sbam-dark-error: mat.define-palette(palette.$error, 200, 100, 700, 100);

$sbam-dark-theme: mat.define-dark-theme(
  (
    color: (
      primary: $sbam-dark-primary,
      accent: $sbam-dark-secondary,
      warn: $sbam-dark-error,
    ),
    density: -2,
  )
);

.dark-theme {
  // TODO: find the right place for drawer colors

  /* &.sb-app-background,
  &.sb-app-background sb-app-layout > .mat-drawer-container {
    @include mixins.base-colors(variables.$text-dark, variables.$app-background-dark);
  } */

  // @include mat.all-component-colors($sbam-dark-theme);
  // @include components.theme($sbam-dark-theme);
}
