@use "sass:map";
@use "@angular/material" as mat;
@use "variables" as var;
@import "mixins";

@mixin styles() {
  .mat-mdc-table {
    .mdc-data-table__content .mat-mdc-row.clickable {
      cursor: pointer;
    }

    // This allows the ripple effect to extend beyond the cell.
    .mat-column-select {
      overflow: initial;
    }

    // reverse the order of sort icon and text (number cell aligns automatically to the right)
    .number-cell-header {
      .mat-sort-header-container {
        flex-direction: row-reverse;

        .mat-sort-header-arrow {
          margin: 0 6px 0 0;
        }

        >div {
          text-align: right;
        }
      }
    }

    .centered-cell {
      text-align: center;

      .mat-sort-header-container {
        justify-content: center;

        >div {
          text-align: center;
          margin-left: auto;
          margin-right: auto;
          justify-content: center;
        }
      }
    }
  }
}

@mixin color($config-or-theme) {
  $config: mat.get-color-config($config-or-theme);

  $is-dark-theme: map.get($config, is-dark);

  $row-radius: var.$base-radius * 3;
  $background: if($is-dark-theme, var.$surface-dark, var.$surface-light);
  $odd-background: if($is-dark-theme, var.$app-background-dark, var.$app-background-light);
  $hover-background: if($is-dark-theme, var.$table-background-hover-dark, var.$table-background-hover-light);

  .mat-mdc-table {
    --mat-table-background-color: #{$background};

    --mdc-typography-body2-font-weight: 400;

    // Disable default border
    .mdc-data-table__cell,
    .mdc-data-table__header-cell {
      border: none;
    }

    .mdc-data-table__content {
      .mat-mdc-row {

        &:not(.custom-odd-bg):nth-child(odd),
        &.odd {
          background-color: $odd-background;
        }

        &.selected,
        &.clickable:hover {
          background-color: $hover-background !important;
        }

        // Apply border radius on cells to fake border on rows
        .mdc-data-table__cell {
          &:first-child {
            border-top-left-radius: $row-radius;
            border-bottom-left-radius: $row-radius;
          }

          &:last-child {
            border-top-right-radius: $row-radius;
            border-bottom-right-radius: $row-radius;
          }
        }
      }
    }
  }

  .mat-mdc-paginator {
    background: transparent;
  }
}
