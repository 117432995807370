@mixin styles() {

  .card-chart {
    display: flex;
    width: 100%;
    justify-content: center;
    align-content: center;
    min-height: 300px;
    flex-grow: 1;
    position: relative;

    > * {
      flex-grow: 1;
    }
  }

  .chart {
    position: relative;
    min-height: 300px;
    width: 100%;
    padding-left: 12px;
    padding-right: 12px;
  }
}
