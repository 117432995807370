@use "sass:map";
@use "sass:color";
@use "@angular/material" as mat;
@use "variables" as var;
@import "mixins";

@mixin styles() {

  sb-badge,
  .badge {
    cursor: default;
  }

  sb-badge>.new-badge,
  .badge {
    display: inline-flex;
    font-size: var.$badge-font-size;
    line-height: var.$badge-line-height;
    font-weight: var.$badge-font-weight;
    padding: var.$badge-padding;
    border-radius: var.$badge-radius;
    align-items: center;

    mat-icon.mat-icon {
      margin-left: var.$badge-icon-padding-left;
      width: var.$badge-icon-size;
      height: var.$badge-icon-size;
      color: inherit;
    }

    &.badge-sm {
      font-size: var.$badge-sm-font-size;
      line-height: var.$badge-sm-line-height;
      padding: var.$badge-sm-padding;
      border-radius: var.$badge-sm-radius;
    }
  }
}

@mixin color($config-or-theme) {
  $config: mat.get-color-config($config-or-theme);
  $is-dark-theme: map.get($config, is-dark);

  sb-badge>.new-badge,
  .badge {
    color: if($is-dark-theme, var.$badge-neutral-text-dark, var.$badge-neutral-text-light);
    background-color: if($is-dark-theme, var.$badge-neutral-background-dark, var.$badge-neutral-background-light);

    &.badge-hover:hover {
      &:before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        opacity: 0.12;
        top: 0;
        left: 0;
        background-color: #000;
      }
    }

    &.badge-primary {
      color: if($is-dark-theme, var.$badge-primary-text-dark, var.$badge-primary-text-light);
      background-color: if($is-dark-theme, var.$badge-primary-background-dark, var.$badge-primary-background-light);
    }

    &.badge-secondary {
      color: if($is-dark-theme, var.$badge-secondary-text-dark, var.$badge-secondary-text-light);
      background-color: if($is-dark-theme, var.$badge-secondary-background-dark, var.$badge-secondary-background-light);
    }

    &.badge-success {
      color: if($is-dark-theme, var.$badge-success-text-dark, var.$badge-success-text-light);
      background-color: if($is-dark-theme, var.$badge-success-background-dark, var.$badge-success-background-light);
    }

    &.badge-warning {
      color: if($is-dark-theme, var.$badge-warning-text-dark, var.$badge-warning-text-light);
      background-color: if($is-dark-theme, var.$badge-warning-background-dark, var.$badge-warning-background-light);
    }

    &.badge-error {
      color: if($is-dark-theme, var.$badge-error-text-dark, var.$badge-error-text-light);
      background-color: if($is-dark-theme, var.$badge-error-background-dark, var.$badge-error-background-light);
    }
  }
}
