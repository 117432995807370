.list-header {
  border-bottom: 1px solid #c3cbd6;

  .list-header__item {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 0.9rem;
    color: gray;
  }
}

.list-items {
  .list-item {
    cursor: pointer;
    border-bottom: 1px solid #e3ebf6;

    & > [class^='col'] {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      padding-top: 0.75rem;
      padding-bottom: 0.75rem;
    }

    &:hover {
      background-color: transparentize($light, 0.7);
    }

    &:focus {
      outline: none;
    }

    .list-item__title {
      width: 100%;
      font-size: 1.2rem;
      font-weight: 600;
    }

    .list-item__date {
      width: 100%;
      font-size: 1rem;
      font-weight: 500;
    }

    .list-item__value {
      width: 100%;
      font-weight: 500;
      font-size: 1.2rem;
    }
  }
}
