//
// card.scss
// Extended from Bootstrap
//
@use 'sass:math';

//
// Bootstrap Overrides =====================================
//

.card:not(.content-list__item):not(.story):not(.goal):not(.user-list) {
  margin-bottom: $spacer;
  border-color: $card-outline-color;
  box-shadow: $card-box-shadow;
  height: calc(100% - #{$spacer});
}

.card.goal,
.card.story,
.card.content-list__item {
  margin-bottom: $spacer;
  border-color: $card-outline-color;
  box-shadow: $card-box-shadow;
}

.content-list__item-actions {
  .btn {
    display: block;
    width: 100%;

    @include media-breakpoint-up(lg) {
      display: inline-block;
      width: auto;
    }
  }
}

.card-header {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  align-items: center;
  min-height: $card-header-min-height;
  padding-top: math.div($card-spacer-y, 2);
  padding-bottom: math.div($card-spacer-y, 2);

  > * {
    flex: 1;
  }
}

.card-title {
  margin-bottom: 0.5rem;
  font-weight: 500;
}

//
// Dashkit ===================================
//

// Card header
//
// Make sure the card header is always the same height with its content
// centered vertically

.card-header-title {
  margin-bottom: 0;
}

.card-header-tabs {
  margin-top: -(math.div($card-spacer-y, 2));
  margin-bottom: -(math.div($card-spacer-y, 2));
  margin-left: 0;
  margin-right: 0;
}

.card-header-tabs .nav-link {
  padding-top: calc((#{$card-header-min-height} - 1em * #{$line-height-base}) / 2) !important;
  padding-bottom: calc((#{$card-header-min-height} - 1em * #{$line-height-base}) / 2) !important;
}

// Card table
//
// Make sure the card table content is aligned with the rest of the
// card content

.card-table {
  margin-bottom: 0;

  thead th {
    border-top-width: 0;
  }

  thead th,
  tbody td {
    &:first-child {
      padding-left: $card-spacer-x !important;
    }

    &:last-child {
      padding-right: $card-spacer-x !important;
    }
  }
}

.card > .table-responsive:first-child > .card-table,
.card > .card-table:first-child {
  > thead,
  > tbody,
  > tfoot {
    &:first-child {
      > tr:first-child {
        > th,
        > td {
          &:first-child {
            border-top-left-radius: $card-border-radius;
          }

          &:last-child {
            border-top-right-radius: $card-border-radius;
          }
        }
      }
    }
  }
}

// Card avatar
//
// Moves card avatar up by 50%

.card-avatar {
  display: block !important;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: $card-spacer-y;
}

.card-avatar-top {
  margin-top: -($card-spacer-x + math.div($avatar-size-base, 2));
}

.card-avatar-top.avatar-xs {
  margin-top: -($card-spacer-x + math.div($avatar-size-xs, 2));
}

.card-avatar-top.avatar-sm {
  margin-top: -($card-spacer-x + math.div($avatar-size-sm, 2));
}

.card-avatar-top.avatar-lg {
  margin-top: -($card-spacer-x + math.div($avatar-size-lg, 2));
}

.card-avatar-top.avatar-xl {
  margin-top: -($card-spacer-x + math.div($avatar-size-xl, 2));
}

.card-avatar-top.avatar-xxl {
  margin-top: -($card-spacer-x + math.div($avatar-size-xxl, 2));
}

// Card dropdown
//
// Places dropdowns in the top right corner

.card-dropdown {
  position: absolute;
  top: $card-spacer-x;
  right: $card-spacer-x;
}

// Card inactive

.card-inactive {
  border-color: $border-color;
  border-style: dashed;
  background-color: transparent;
  box-shadow: none;
}
